import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {H1, P} from "../components/Typography"
import styled from "styled-components"

const ErrorCode = styled(H1)`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
    margin-top: ${props => props.theme.spacings.largest};
  }
`

const ErrorMessage = styled(P)`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 6;
    grid-row: 1 / span 1;
    margin-top: ${props => props.theme.spacings.largest};
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <ErrorCode>404</ErrorCode>
    <ErrorMessage>Cette page n'existe pas</ErrorMessage>
  </Layout>
)

export default NotFoundPage
